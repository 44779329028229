<template>
  <div class="company">
    <van-list v-model="loading" :finished="finished" finished-text=" " loading-text=" " @load="onLoad">
      <div
        class="company-item"
        v-for="item in companyList"
        :key="item.store_id"
        :title="item.store_name"
        @click="getCompanyDetails(item.store_id)"
      >
        <img :src="item.store_logo" />
        <div class="company-item-name">
          <span>{{ item.store_name }} </span>
          <div class="company-item-buttom" style="display: flex;flex-direction: row;width: 100%;">
            <div>认证商家</div>
            <div>{{ item.sc_name }}</div>
          </div>
        </div>
        <div class="">
          <div class="clear"></div>
          <i class="iconfont icon-xing" style="color: #fe2b54;"> </i>
          <span class="collect-num">{{ item.store_collect }}</span>
          <span>粉丝</span>
        </div>
        <div class="clear"></div>
      </div>
    </van-list>
    <div style="height: 100px"></div>
  </div>
</template>

<script>
import { getCompanyList } from '@/network/company'
import { getType } from 'network/home'
export default {
  name: 'More',
  data() {
    return {
      typeMap: null,
      myStatus: 0,
      applyState: 0,
      membership_fee: 0,
      store: {},
      classId: '',
      pageNum: 1,
      searchKey: '',
      companyList: [],
      loading: false,
      finished: false
    }
  },
  created() {},
  methods: {
    async onLoad() {
      const _self = this
      if (_self.typeMap == null) {
        _self.typeMap = new Map()
        await getType().then((res) => {
          res.data.result.forEach((item) => {
            this.typeMap.set(item.id, item.name)
          })
        })
      }
      getCompanyList(this.classId, this.pageNum, this.searchKey).then((res) => {
        if (res.data && res.data.status == 1) {
          const result = res.data.result
          if (result.store_list.length > 0) {
            result.store_list.forEach((item) => {
              item.sc_name = _self.typeMap.get(item.sc_id)
              item.store_logo = item.store_logo ? process.env.VUE_APP_IMG_PATH + item.store_logo : ''
            })
            _self.companyList = _self.companyList.concat(result.store_list)
            _self.pageNum += 1
            _self.loading = false
            _self.finished = false
          } else {
            _self.loading = true
            _self.finished = true
          }
        }
      })
    },
    getCompanyDetails(companyId) {
      this.$router.push('/companyDetail/' + companyId)
    },
    apply(status) {
      switch (status) {
        case 0:
          this.$router.push('/companyApply')
          break
        case 1:
          this.$router.push('/companyDetail/' + this.store.store_id)
          break
        case 2:
          this.$router.push('/companyPay')
          break
      }
    }
  }
}
</script>
<style scoped>
.company .item {
  margin-top: 5px;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}
.company .item .logo,
.company .item .content,
.company .item .content .name {
  float: left;
}
.company .item .clear {
  clear: both;
}
.company .item .logo img {
  width: 60px;
  height: 60px;
}
.company .item .content {
  line-height: 24px;
  margin-left: 6px;
  font-size: 14px;
}

.company .item .content .addr {
  color: #555;
}
.company .item .content .addr .van-button--default {
  border-radius: 60px;
  font-size: 11px;
  margin-top: 8px;
  margin-right: 10px;
  padding: 2px 10px;
  background-color: #fff;
  border: 1px solid #666;
}
.company .item .collect {
  margin-top: 36px;
  font-size: 9px;
  color: #888;
  float: right;
}
.company .item .pic img {
  width: 32px;
  margin-bottom: 14px;
  text-align: right;
  color: #888;
}
.company .item .collect img {
  vertical-align: text-top;
  width: 12px;
  color: #888;
}
.company .item .collect .collect-num {
  color: #ff0000;
  margin: 0 2px 0 5px;
}

.company-item {
  width: 100%;
  font-size: 14px;
  display: flex;
  margin: 15px 0;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(220, 220, 220, 0.3);
}
.company-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}
.company-item .company-item-name {
  margin-top: 5px;
  width: 58%;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.company-item-name span {
  margin-bottom: 6px;
  font-size: 14px;
}
.company-item-name a {
  font-size: 12px;
}
.company-item-buttom {
  width: 50%;
}
.company-item-buttom div {
  background-color: rgba(220, 220, 220, 0.5);
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}

.user-other {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  transition: all 0.5s;
}
</style>
